import { BandcampPlayer, BandcampPlayerProps } from "../BandcampPlayer";
import MusicSources from "../../config/music-sources.json";
import "./MusicPage.css";

export function MusicPage(): JSX.Element {
    if (MusicSources.length === 0) {
        return <>No music to show! :O</>;
    }
    return <>
    <div className="container" style={{textAlign:"center"}}>
        {
            (MusicSources as BandcampPlayerProps[]).map((s,i) => <BandcampPlayer key={i} {...s}/>)
        }
    </div>
    <div className="soundcloud-links">
        <h6>SOUNDCLOUD:</h6>
        <a href="https://soundcloud.com/kaliane-van">
            @KalianeVan
        </a>
        <a href="https://soundcloud.com/manaramantra">
            @NavEnaliak
        </a>
        <a href="https://soundcloud.com/sychedelicongsboutheun">
            @PsychedelicSongsAboutTheSun
        </a>
    </div>

    </>;
}
